//
// The Dark Mode System
//

// Helper functions to toggle dark mode
function enableDarkMode() {
    document.body.classList.add('dark-mode');
    localStorage.setItem('theme', 'dark');
}

function disableDarkMode() {
    document.body.classList.remove('dark-mode');
    localStorage.setItem('theme', 'light');
}

// Detects user's color scheme preferences
function detectColorScheme() {
    try {
        // Default to light theme
        let theme = 'light';

        // Check localStorage for saved theme preference
        if (localStorage.getItem('theme')) {
            theme = localStorage.getItem('theme');
        } 
        // If no localStorage preference, check system preference
        else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            theme = 'dark';
        }

        // Apply the theme
        theme === 'dark' ? enableDarkMode() : disableDarkMode();

        // Listen for system preference changes
        if (window.matchMedia) {
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
                e.matches ? enableDarkMode() : disableDarkMode();
            });
        }
    } catch (error) {
        console.error('Error detecting color scheme:', error);
    }
}

// Initialize theme detection on page load
document.addEventListener('DOMContentLoaded', () => {
    detectColorScheme();

    // Attach event listener for manual toggle
    const darkModeToggle = document.getElementById('dark-mode-toggle');
    if (darkModeToggle) {
        darkModeToggle.addEventListener('click', () => {
            const currentTheme = localStorage.getItem('theme');
            currentTheme === 'light' ? enableDarkMode() : disableDarkMode();
        });
    } else {
        console.warn('Dark mode toggle button not found.');
    }
});
